@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin";

$pog-primary: #0079c2;
$pog-secondary: #ef8d7c;
$border-gray: #bdc3c7;
$grid-header-background: #0079c2;

.ag-theme-balham {
    @include ag-theme-balham(
        (
            // use theme parameters where possible
            balham-active-color: purple
        )
    );

    .ag-header {
        background-color: $grid-header-background;
    }

    .ag-header-row {
        color: #fff;
        .ag-input-field-input {
            color: #000 !important;
        }
    }

    .ag-header-icon {
        color: #fff;
    }

    .ag-row {
        height: 36px;
    }

    .ag-cell {
        line-height: 34px;
    }
    .ag-popup {
        position:initial;
    }
    .ag-menu {
        background:$primary;
    }
}

// AG Grids pinned bottom row
.ag-floating-bottom {
    border-bottom: 1px solid $border-gray;
    box-shadow: 0 5px 15px $border-gray;
}

.ag-floating-bottom .ag-cell {
    font-weight: bold;
    padding: 0;
}

// AG Grids pagination
.ag-paging-panel .ag-paging-row-summary-panel,
.ag-paging-panel .ag-paging-page-summary-panel {
    color: #000;
}

// AG Grids filters
.ag-filter {
    background-color: $pog-primary;
}

.ag-filter .ag-filter-body .ag-wrapper input {
    margin-top: 4px;
    padding: 4px;
}

.ag-filter .ag-filter-body .ag-wrapper input:focus {
    border: 1px solid $pog-primary;
    box-shadow: 0 0 10px $pog-primary;
}

.ag-root {
    font-size: $type-size-200;
}

.ag-cell-value,
.ag-group-value {
    overflow: visible;
}

.context-menu {
    display: none;
    position: absolute;
    top: 2rem;
    left: 0.25rem;
    background: white;
    padding: 0.75rem;
    box-shadow: $shadow-100;
    border-radius: 0.25rem;
    border: 1px solid rgba($primary, 1);

    &.active {
        display: grid;
        min-width: 8rem;
    }

    button,
    a {
        font-size: $type-size-150;
        display: inline-block;
        padding: 0;
        border: none;
        text-align: left;
        color: $primary;
        background: none;
        appearance: none;
        line-height: 1.5em !important;

        & + * {
            margin-top: 0.5rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &-btn {
        font-size: $type-size-100;
        font-weight: bold;
        line-height: auto;
    }
}

.table-responsive{
    height: 800px;
}

@media (max-width: 600px) {
    .table-responsive{
        height: 400px;
    }   
}
