h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

.page-title {
    font-weight: 200;
    font-size: $type-size-600;
    padding-bottom: 0.5rem;

    @include desktop-small {
        font-size: $type-size-800;
    }
}

.section-title {
    font-weight: 700;
    font-size: $type-size-400;
    padding-bottom: 0.5rem;

    @include desktop-small {
        font-size: $type-size-500;
    }
}
