@import url("https://fonts.googleapis.com/css2?family=Overpass+Mono&family=Overpass:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");

/*

    OVERPASS
    400, 400i
    700, 700i
    900, 900i

    OVERPASS MONO
    400

*/

$overpass: "Overpass", sans-serif;
$overpass-mono: "Overpass Mono", monospace;

$header-nav-link-font-weight: inherit;

$font-family-sans-serif: $overpass;
$font-size: 16px;
