.card {
    box-shadow: $shadow-100;
    border-radius: 0.25rem;

    &-55 {
        max-width: 55rem;
    }

    &-header {
        background: unset;
        margin-bottom: 0.75rem;
        border-bottom: 1px solid rgba(black, 0.15);
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        border-top: 0.5rem solid $primary;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    &-actions {
        & > * + * {
            margin-left: 1rem;
        }
    }

    &-title {
        font-size: $type-size-500;
        font-weight: 700;
        margin: 0;
        padding: 0;
    }

    &-body {
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
}
