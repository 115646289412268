.modal-header {
    background-color: $modal-header-background;
    padding: 0.5rem 1rem;
}

.modal-header .close {
    line-height: inherit;
}

.modal-content {
    top: 150px;
}
