$sizes: (
    1: 0.25rem,
    2: 0.5rem,
    3: 1rem,
    4: 2rem,
    5: 3rem,
    6: 4rem,
);

@each $level, $size in $sizes {
    .m-#{$level}.m-#{$level} {
        margin: $size !important;
    }

    .mt-#{$level}.mt-#{$level} {
        margin-top: $size !important;
    }

    .mr-#{$level}.mr-#{$level} {
        margin-right: $size !important;
    }

    .mb-#{$level}.mb-#{$level} {
        margin-bottom: $size !important;
    }

    .ml-#{$level}.ml-#{$level} {
        margin-left: $size !important;
    }

    .p-#{$level}.p-#{$level} {
        margin: $size !important;
    }

    .pt-#{$level}.pt-#{$level} {
        margin-top: $size !important;
    }

    .pr-#{$level}.pr-#{$level} {
        margin-right: $size !important;
    }

    .pb-#{$level}.pb-#{$level} {
        margin-bottom: $size !important;
    }

    .pl-#{$level}.pl-#{$level} {
        margin-left: $size !important;
    }
}
