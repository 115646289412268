.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6b6b6b;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $button-border-and-background;
}

input:focus + .slider {
    box-shadow: 0 0 1px $button-border-and-background;
}

input:checked + .slider:before {
    -webkit-transform: translateX(77px);
    -ms-transform: translateX(7px);
    transform: translateX(77px);
}

.on,
.off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
}

.on {
    display: none;
    color: $button-text-color;
}

input:checked + .slider .on {
    display: block;
}

input:checked + .slider .off {
    display: none;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
