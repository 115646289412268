$type-size-050: clamp(0.5rem, 0.42rem + 0.38vw, 0.675rem);
$type-size-100: clamp(0.6rem, 0.52rem + 0.43vw, 0.78rem);
$type-size-150: clamp(0.66rem, 0.58rem + 0.48vw, 0.88rem);
$type-size-200: clamp(0.72rem, 0.62rem + 0.52vw, 0.94rem);
$type-size-300: clamp(0.87rem, 0.74rem + 0.62vw, 1.13rem);
$type-size-400: clamp(1.04rem, 0.89rem + 0.74vw, 1.35rem);
$type-size-500: clamp(1.25rem, 1.07rem + 0.89vw, 1.63rem);
$type-size-600: clamp(1.5rem, 1.29rem + 1.07vw, 1.95rem);
$type-size-700: clamp(1.8rem, 1.54rem + 1.29vw, 2.34rem);
$type-size-800: clamp(2.16rem, 1.85rem + 1.54vw, 2.81rem);
$type-size-900: clamp(2.59rem, 2.22rem + 1.85vw, 3.37rem);
$type-size-1000: clamp(3.11rem, 2.67rem + 2.22vw, 4.625em);
$type-size-1500: clamp(5rem, 6.5rem + 3.33vw, 7rem);
$type-size-2000: clamp(6.22rem, 5.25rem + 4.44vw, 8.08rem);
