.breadcrumb {
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #e9eeef;
    background-color: #fff;
}
// Breadcrumb active text contrast increase
.breadcrumb-item.active {
    color: #474d52;
}
