.btn {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border-radius: 0.25rem;
    border: none;

    &:focus,
    &.focus {
        outline: 0;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: 0.6;
    }
}

.btn {
    font-size: $type-size-200;
    line-height: 1.15em;
    padding: 0.5rem 0.75rem 0.375rem;
    transition: all 300ms ease;

    .fa,
    .fas {
        display: inline-block;
        margin-right: 0.25rem;
    }

    &-primary {
        background: $primary;
        color: $white;

        &:hover {
            background: $primary;
            color: $white;
        }
    }

    &-secondary {
        background: transparent;
        color: $primary;
        border: 1px solid $primary;

        &:hover {
            background: $primary;
            color: white;
            border: 1px solid $primary;
        }
    }

    &-toggle {
        color: #212529;
        background-color: #ababab;
        border-color: #a4a4a4;

        &:hover {
            cursor: pointer;
            color: #fff;
            background-color: $button-toggle-hover;
            border-color: $button-toggle-hover;
        }
    }
}

.btn-toggle:not(:disabled):not(.disabled).active,
.show > .btn-toggle.dropdown-toggle {
    background-color: $button-border-and-background;
    border-color: $button-border-and-background;
    color: #fff;
}

.filter-btn-group {
    &.align-right {
        display: flex;
        justify-content: flex-end;
    }
}
