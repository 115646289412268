body {
    line-height: 1;
    background: linear-gradient(
            180deg,
            rgba(0, 156, 243, 0.05) 0%,
            rgba(55, 216, 90, 0.05) 58.85%
        ),
        #ffffff;
}

.site {
    min-height: 70vh;
}

.site-wrapper {
    width: calc(100% - 4rem);
    max-width: 1920px;
    margin: 3rem auto;
    border-radius: 8px;
    box-shadow: 0 0 1.5rem rgba(black, 0.07);
    background: rgba(white, 0.9);
}

.list-view,
.detail-view {
    padding: 2rem;
}

.actions-bar {
    display: flex;
    justify-content: flex-end;
    height: 40px;
}

.grid-12 {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

@for $i from 1 through 12 {
    .g-col-#{$i} {
        grid-column: span 12;
        @include desktop-small {
            grid-column: span $i;
        }
    }
}

.flex {
    &-start {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-end {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
