.qa-warning {
    margin: 0;
    padding: 1rem;
    font-size: $type-size-200;
    background: black;
    text-align: center;
    color: white;
    z-index: 1;
    position: relative;

    span.fa {
        padding-right: 0.5rem;
        color: red;
    }
}

.site-logo {
    max-width: 24rem;

    &-mobile {
        max-width: 24rem;
        margin-bottom: 2rem;
        @include desktop-small {
            display: none;
        }
    }
}

.site-nav {
    background: #E8E8E8;
    color: black;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__wrapper {
        @include desktop-small-max {
            padding: 2rem;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 7777;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 75%;
            background-color: $white;
            outline: 0;
            transform: translateX(-30rem);
            transition: transform 0.3s ease-in-out;
            visibility: hidden;

            &.active {
                visibility: visible;
                transform: translateX(0);
                filter: drop-shadow(0 0 6px #aaa);
            }
        }

        @include desktop-small {
            padding-top: 0.5rem;
            flex: 1;
            display: flex;
            align-items: center;
        }
    }

    &__links {
        @include desktop-small {
            display: flex;
            align-items: center;
        }
    }

    &__utilities {
        margin-top: 2rem;
        border-top: 1px solid white;
        padding-top: 2rem;

        @include desktop-small {
            padding-top: 0;
            margin-left: auto;
            margin-top: 0;
            border-top: none;
        }
    }
}

.hamburger-btn {
    padding: 0;
    margin-left: auto;
    background: transparent;
    margin: 0;
    border: none;
    display: block;  
    min-width: 28px;

    @include desktop-small {
        display: none;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 2rem;
        max-height: 2rem;
        fill: #0079c2;
    }
}

.dropdown {
    &-menu {
        display: none;
        @include desktop-small {
            position: absolute;
            z-index: 1111;
            top: 2rem;
            left: 0;
            background: $white;
            width: 16rem;
            border-radius: 0.25rem;
            box-shadow: $shadow-100;
        }

        &.active {
            display: block;
        }
    }

    &-item {
        display: block;
        font-size: $type-size-300;
        padding: 1rem 0;
        color: black;

        @include desktop-small {
            padding: 1rem;
            transition: all 300ms ease;
            font-size: $type-size-200;
            color: black;
        }

        &:hover {
            color: #0079c2;

            @include desktop-small {
                color: #0079c2;
            }
        }

        &:first-of-type {
            @include desktop-small {
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
            }
        }

        &:last-of-type {
            padding-bottom: 0;

            @include desktop-small {
                padding-bottom: 1rem;
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
        }

        & + .dropdown-item {
            border-top: 1px solid rgba(white, 0.05);

            @include desktop-small {
                border-top: 1px solid rgba(black, 0.05);
            }
        }
    }

    &-toggle {
        &:after {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
        }
    }
}

.nav {
    &-item {
        & + .nav-item {
            margin-top: 1rem;
            border-top: 2px solid rgba(white, 0.05);
            padding-top: 1.5rem;

            @include desktop-small {
                margin-top: 0;
                margin-left: 2rem;
                border-top: none;
                padding-top: 0;
            }
        }

        .username {
            text-transform: none;
            letter-spacing: 0;
        }
    }

    &-link {
        text-transform: uppercase;
        letter-spacing: 0.05em;
        display: inline-block;
        color: black;
        font-size: $type-size-300;

        @include desktop-small {
            font-size: $type-size-150;
        }

        &:hover {
            color: black;
        }
    }
}
