.image {
    display: table;
    clear: both;
    text-align: center;
    margin: 1em auto;
}

.image > img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 50px;
}

img {
    display: block;
    max-width: 100%;
}

.image-style-side,
.image-style-align-left,
.image-style-align-center,
.image-style-align-right {
    max-width: 50%;
}

.image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

.image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

.image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

.image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}
