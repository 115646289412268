@import "home";

@import "custom-page-list";

@import "custom-page-detail";

@import "user-invite";

@import "user-list";

.edit-definition {
    .ck {
        &-content {
            min-height: 16rem;
        }
    }
}
