// Navbar link contrast increase
.navbar-dark .navbar-nav .nav-link {
    color: #e1e1e1;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #ffffff;
}

.nav-link {
    font-weight: $header-nav-link-font-weight;
}

.nav-item,
.navbar-text {
    white-space: nowrap;
    list-style: none;
    list-style-image: none;
}

.navbar {
    padding: 1rem 2rem;
    box-shadow: 0 0.25rem 1rem rgba(black, 0.2);
}

.navbar-brand {
    max-width: 8rem;
    padding: 0;
    margin: 0 2rem 0 0;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
    display: inline-block;
}

.navbar-nav {
    display: flex;
    flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    @include desktop-small {
        flex-direction: row;
    }

    .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    .dropdown-menu {
        position: static;
        float: none;
    }
}

.navbar-text {
    display: inline-block;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;

    text-decoration: none;
    cursor: pointer;

    // Disabled state lightens text
    &.disabled {
        pointer-events: none;
        cursor: default;
    }

    &.active {
        text-decoration: underline;
        font-weight: 500;
    }
}
