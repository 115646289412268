// NORMALIZE
@import "scss/normalize";

// VARIABLES & MIXINS
@import "scss/variables/variables";
@import "scss/mixins/mixins";

// THIRD PARTY INCLUDES (after variables)
@import "~font-awesome/css/font-awesome.css";
@import "~leaflet/dist/leaflet.css";
@import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
//@import "~leaflet-draw/dist/leaflet.draw.css";

// UTILITIES
@import "scss/utilities/utilities";

// CUSTOM BOOTSTRAP
@import "scss/bootstrap-custom/bootstrap-custom";

// BASE STYLING
@import "scss/a11y/a11y";
@import "scss/typography/typography";
@import "scss/layout/layout";

// BASE COMPONENTS
@import "scss/forms/forms";
@import "scss/charts/charts";
@import "scss/tables/tables";
@import "scss/maps/maps";
@import "scss/elements/elements";

// HEADER & FOOTER
@import "scss/header/header";
@import "scss/footer/footer";

// PAGES
@import "scss/pages/pages";

@import "@angular/material/theming";
@import "scss/theme/custom-material-theme";
@include mat-core();

$my-app-primary: mat-palette($mat-blue-grey, 900);
$my-app-accent: mat-palette($md-deltablue, 500);
$my-app-warn: mat-palette($mat-amber, A700);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
}

footer {
  margin-top: auto;
}

.material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.notif-success {
    color: #202020 !important;
    background-color: #ffffff !important;
    border-color: $primary !important;
    .mat-simple-snackbar-action {
        color: $primary !important;
    }
}

.mat-tooltip {
    font-size: 14px;
}

/* ESA Material Button Styling */

.disabled {
    opacity: 0.5;
    cursor: default;
}

.clear-button {
    background-color: transparent;
    color: #2D2D2D;
}

.clear-button:hover {
    background-color: #e4e4e4;
    color: #2D2D2D;
}

.primary-button {
    background-color: #0079c2;
    color: #fff;

    &.tab {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0 16px;
    }
}

.primary-button:hover {
    background-color: #034973;
    color: #fff;
}

.secondary-button {
    background-color: #DFDFDF;
    color: #2D2D2D;

    &:not(.tab) {
        margin-left: 4px;
    }

    &.tab {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0 16px;
    }
}

.secondary-button:hover {
    background-color: #c6c6c6;
    color: #2D2D2D;
}

// This will hide the TinyMCE "UGPRADE" button, there's a but that makes the 2nd load of the editor always show the button regardless of the "promotion" value being set to false.
.tox .tox-promotion {
    display:none !important;
}

/* ESA Material FF Styling: Dropdown */

.mat-select-panel {
    max-height: 400px !important;
}

.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
    font-size: inherit;
    line-height: 1.5em !important;
    height: 1.5em !important;
}
