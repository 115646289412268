.home-splash {
    display: grid;
    gap: 0;

    @include tablet {
        grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    }

    .photo {
        aspect-ratio: 16 / 9;

        @include tablet {
            aspect-ratio: unset;
        }

        img {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .info {
        padding: 2rem;
    }
}
