/* HTML5 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden] {
    display: none!important;
}

/* HTML & BODY */
html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
html,
button,
input,
select,
textarea {
    font-family: sans-serif;
}
body {
    margin: 0;
    font-size: 1em;
    line-height: 1;
}

/* Links */
a {
    text-decoration: none;
}
a:focus {
    outline: none;
}
a:hover,
a:active {
    outline: none;
}

/* Typography */
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: 600;
}
i,
em {
    font-style: italic;
}
blockquote {
    margin: 1em 40px;
}
dfn {
    font-style: italic;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
ins {
    background: #ff9;
    color: #000;
    text-decoration: none;
}
mark {
    background: #ff0;
    color: #000;
}
p,
pre {
    margin: 1em 0;
}
code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    _font-family: "courier new", monospace;
    font-size: 1em;
}
pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}
q {
    quotes: none;
}
q:before,
q:after {
    content: "";
    content: none;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    font-weight: normal;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}

/* Lists */
dd {
    margin: 0;
}
// ul,
// ol {
//     list-style: none;
//     list-style-image: none;
//     margin: 0;
//     padding: 0;
// }

/* Embedded Content */
img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}
svg:not(:root) {
    overflow: hidden;
}

/* Figure */
figure {
    margin: 0;
}

/* Forms */
form {
    margin: 0;
}
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
legend {
    border: 0;
    *margin-left: -7px;
    padding: 0;
    white-space: normal;
}
button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
}
button,
input {
    line-height: normal;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
    *overflow: visible;
}
button[disabled],
input[disabled] {
    cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *width: 13px;
    *height: 13px;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/* Tables */
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td {
    vertical-align: top;
}

/* Absolute Positioning */
div,
article,
section,
header,
footer,
nav,
figure,
li {
    position: relative;
}

/* Border-Box */
* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
