/* For use in src/lib/core/theming/_palette.scss */
$md-deltablue: (
    50 : #e1ecf1,
    100 : #b4cfdd,
    200 : #82b0c6,
    300 : #4f90af,
    400 : #2a789e,
    500 : #04608d,
    600 : #035885,
    700 : #034e7a,
    800 : #024470,
    900 : #01335d,
    A100 : #8ec3ff,
    A200 : #5ba8ff,
    A400 : #288cff,
    A700 : #0e7fff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);