.required:after {
    content: "\f0e7";
    font-weight: 900;
    margin-left: 5px;
    font-size: 10px;
    font-family: "Font Awesome 5 Free";
    vertical-align: top;
}

.form {
    .required-note {
        border-top: 1px solid rgba(black, 0.05);
        padding-top: 1rem;
        margin-top: 1rem;
    }

    &.grid-12 {
        gap: 2rem 2rem;
    }
}

.field {
    * {
        &:focus,
        &:active {
            outline-color: $primary;
        }
    }

    &-label {
        display: block;
        font-weight: bold;
        font-size: $type-size-150;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        padding-bottom: 0.5rem;
        margin-bottom: 0;
    }

    &-checkbox-single {
        display: flex;
        align-items: center;
    }

    input[type="text"],
    select {
        width: 100%;
        border-radius: 0.25rem;
        padding: 0.5rem 0.75rem;
        border: 1px solid rgba(black, 0.2);

        @include placeholder {
            color: rgba(black, 0.5);
        }
    }

    input[type="text"] {
        height: 3rem;
        line-height: 3rem;
        font-size: $type-size-300;
    }

    select {
        height: 3rem;
        font-size: $type-size-300;
    }
}

.checkbox-label {
    margin: 0;
    padding-left: 0.5rem;
}
