body {
    font-family: $overpass;
}

a {
    color: $primary;
}

.systemText {
    font-style: italic;
}

// Add Abbr color
abbr[title],
abbr[data-original-title] {
    text-decoration-color: $info;
}

// Maintain link color on hover
a:hover {
    color: #205c90;
}

.intro-text {
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
}

@import "font-awesome";

@import "headings";

@import "copy";
