@import "leaflet";



.pog-canister-cluster {
    color: #fff;
    background-color: rgba(#CB8427, 0.6);
    div {
        background-color: rgba(#CB8427, 0.6);
    }
}

.pog-equipment-cluster {
    color: #fff;
    background-color: rgba(#2A81CB, 0.6);
    div {
        background-color: rgba(#2A81CB, 0.6);
    }
}
