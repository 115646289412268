.copy {

    & > * + * {
        margin-top: 1.5rem;
    }

    li + li {
        padding-top: 1rem;
    }

    &-55 {
        max-width: 55rem;
    }
}
